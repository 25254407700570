import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { navigate } from 'gatsby';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function Contact() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <Helmet>
        <title>Contact | Justin Kwan Lee</title>
        <meta name="description" content="Contact Justin Kwan Lee" />
      </Helmet>
      <div id="main">
        <section id="one">
          <h1>Contact</h1>
          <p>
            You can use the form below to get in touch with me and I will get
            back to you as soon as possible.
          </p>
          <form
            name="contact"
            method="post"
            action="/contact-complete/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <input type="bot-field" onChange={handleChange} />
            </p>
            <p>
              <label>
                Your Name:{' '}
                <input type="text" name="name" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                Your Email:{' '}
                <input type="email" name="email" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                Message: <textarea name="message" onChange={handleChange} />
              </label>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </section>
      </div>
    </Layout>
  );
}

export default Contact;
